import { NotificationImportant } from "@mui/icons-material";
import { Grid } from "@mui/material";
import { resiliencyClosed, getTransferSwitchOpen, getTransferSwitchClosed } from "../../constants/bannerMessages";
import { useOpco } from "../../context/opcoContext";
import "./Banner.scss";

export default function Banner({ program, status = "closed" }) {

  //get banner status for Transfer Switch from opco data
  const { opcoData } = useOpco();
  const openProgram = opcoData.openProgram;

  return (
    <Grid container item xs={12} class="warning">
      <Grid item xs={1} class="iconContainer">
        <NotificationImportant class="warningIcon" />
      </Grid>
      <Grid item xs={11}>
        { program === "resiliency" && status === "closed" && resiliencyClosed }
        
        { program === "transferSwitch" && (
          openProgram
            ? getTransferSwitchOpen(opcoData)
            : getTransferSwitchClosed(opcoData)
        )}
      </Grid>
    </Grid>
  );
}
