import "./Navbar.scss";
import logo from "../../assets/EntergyLogo_MAIN_Color.png";
import { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import { Drawer, IconButton, useMediaQuery } from "@mui/material";
import { Menu } from "@mui/icons-material";
import { useOpco } from "../../context/opcoContext";

export default function Navbar({ openContactDialog, program }) {
  
  const [openDrawer, setOpenDrawer] = useState(false);
  const desktopScreenSize = useMediaQuery("(min-width:1024px)");

  const { opcoData: { liveOpco, openProgram }, opco } = useOpco();

  return (
    <nav className="navbar">
      <navbar-content>
        {program === 'resiliency' &&
        <>
        <navbar-logo>
          <Link to="/">
            <img src={logo} className="mainLogo" alt="Entergy Logo" />
          </Link>
        </navbar-logo>

        {desktopScreenSize ? (
          <navbar-link-group>
            <navbar-link>
              <HashLink to="#faq">FAQs</HashLink>
            </navbar-link>
            <navbar-link>
              <HashLink to="#top" onClick={openContactDialog}>
                Contact
              </HashLink>
            </navbar-link>
            <navbar-link>
              <NavLink to="/smb">
                Business Customers
              </NavLink>
            </navbar-link>
          </navbar-link-group>
        ) : (
          <navbar-link-group>
            <IconButton onClick={()=>setOpenDrawer(prevState=>!prevState)}>
              <Menu />
            </IconButton>
            <Drawer
              className="drawer"
              anchor="right"
              open={openDrawer}
              onClose={()=>setOpenDrawer(false)}
            >
              <navbar-link>
                <HashLink to="#faq" onClick={()=>setOpenDrawer(false)}>FAQs</HashLink>
              </navbar-link>
              <navbar-link>
                <HashLink to="#top" onClick={()=>{setOpenDrawer(false); openContactDialog();}}>
                  Contact
                </HashLink>
              </navbar-link>
              <navbar-link>
                <NavLink to="/smb" onClick={()=>setOpenDrawer(false)}>
                  Business Customers
                </NavLink>
              </navbar-link>
            </Drawer>
          </navbar-link-group>
        )}
        </>
      }
      {program === 'transferSwitch' &&
        <>
        <navbar-logo>
          <Link to="/transferSwitch">
            <img src={logo} className="mainLogo" alt="Entergy Logo" />
          </Link>
        </navbar-logo>
        {liveOpco && (
          <>
            {desktopScreenSize ? (
              <navbar-link-group>
                <navbar-link>
                  <NavLink to={`/transferSwitch/${opco}`} end>
                    Home
                  </NavLink>
                </navbar-link>
                <navbar-link>
                  <HashLink to="#faq" >FAQs</HashLink>
                </navbar-link>
                <navbar-link>
                  <NavLink to={`/transferSwitch/${opco}/safety`}>
                    Safety
                  </NavLink>
                </navbar-link>
                { openProgram && 
                  <navbar-link>
                    <NavLink to={`/transferSwitch/${opco}/signUp`}>
                      Sign up
                    </NavLink>
                  </navbar-link>
                }
              </navbar-link-group>
            ) : (
              <navbar-link-group>
                <IconButton onClick={()=>setOpenDrawer(prevState=>!prevState)}>
                  <Menu />
                </IconButton>
                <Drawer
                  className="drawer"
                  anchor="right"
                  open={openDrawer}
                  onClose={()=>setOpenDrawer(false)}
                >
                  <navbar-link>
                    <NavLink to={`/transferSwitch/${opco}`} end onClick={()=>setOpenDrawer(false)}>
                      Home
                    </NavLink>
                  </navbar-link>
                  <navbar-link>
                    <HashLink to="#faq" onClick={()=>setOpenDrawer(false)}>FAQs</HashLink>
                  </navbar-link>
                  <navbar-link>
                    <NavLink to={`/transferSwitch/${opco}/safety`} onClick={()=>setOpenDrawer(false)}>
                      Safety
                    </NavLink>
                  </navbar-link>
                  { openProgram && 
                    <navbar-link>
                      <NavLink to={`/transferSwitch/${opco}/signUp`} onClick={()=>setOpenDrawer(false)}>
                        Sign up
                      </NavLink>
                    </navbar-link>
                  }
                </Drawer>
              </navbar-link-group>
            )}
          </>
        )}
        </>
}
      </navbar-content>
    </nav>
  );
}
