import homeImg1 from '../../assets/ts-image3.jpg';
import homeImg2 from '../../assets/ts-image2.jpeg';
import { InfoOutlined } from '@mui/icons-material'
import { StyledTooltip } from '../../components/StyledTooltip';

import React from 'react';
import { StyledButtonSentenceCase } from '../../components/StyledButton';
import { Link } from 'react-router-dom';

export const getHomeCarousel = (opcoData, opco) => {

    const { displayPrice, monthlyPrice, price7year, openProgram } = opcoData

    return [
    {
        "name" : 
            <>
            <p>
                Participate in the Transfer Switch with Surge Protection Pilot Program for as low as ${displayPrice} per month
                <sup>
                    <StyledTooltip arrow title={
                        <React.Fragment>
                            The cost displayed is an approximate figure. The actual costs for the device, installation, and services amount to ${price7year}, while the operational and maintenance expenses total ${monthlyPrice}.
                        </React.Fragment>
                    }>
                        <InfoOutlined style={{width: '1rem', height: '1rem', color: '#000'}}/>
                    </StyledTooltip> 
                </sup>
            </p>
            </>,
        "description" : 
        <>
            <p>
                The transfer switch with surge protection device is a UL-listed and socket-mounted transfer switch, designed to offer a safe and convenient solution for connecting portable generator power to your home within minutes.  
                <br/>
                With surge protection, there's no need for a manual transfer switch. It is installed behind your electric meter and facilitates the direct delivery of generator power to your breaker box, eliminating the inconvenience of running power cords through your home. Additionally, this device serves as a reliable surge protector, keeping your electrical system safe.
                <br/>
            </p>
            {openProgram ? 
                <Link to={`/transferSwitch/${opco}/signUp`} ><StyledButtonSentenceCase >Sign up</StyledButtonSentenceCase></Link>
            :
                <StyledButtonSentenceCase disabled >Sign up</StyledButtonSentenceCase>
            }
        </>,

        "image" : homeImg1,
        "imageAlt" : "Generator"
    },
    {
        "name" : "Key points about the transfer switch with surge protection device",
        "description" : 
        <>
            <ul>
                <li>The transfer switch with surge protection device is specifically designed for use with a portable generator that is connected temporarily to a home and provides a maximum continuous power output of less than 8,500 watts (30 amps). A larger compatible generator can be used, but full output of the generator may not be utilized.</li>
                <li>A 20-foot cord is provided to conveniently connect your generator to the meter socket transfer switch.</li>
                <li>A 240V, four-pronged outlet on the generator is required.</li>
                <li>The device is not compatible with electric services rated above 200 amps.</li>
                <li>
                    <a style={{fontWeight: '600'}}href='https://www.globalpowerproducts.com/wp-content/uploads/2024/01/How-to-choose-a-generator_FINAL.pdf' target="__blank">
                        {`The 30-amp trifecta transfer switch with surge protection device `}
                    </a>
                    is the only device model available for the pilot.
                </li>
            </ul>
            {openProgram ? 
                <Link to={`/transferSwitch/${opco}/signUp`} ><StyledButtonSentenceCase >Sign up</StyledButtonSentenceCase></Link>
            :
                <StyledButtonSentenceCase disabled >Sign up</StyledButtonSentenceCase>
            }        </>,
        "image" : homeImg2,
        "imageAlt" : "Photograph"
    }
];
}

