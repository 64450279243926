export const resiliencyClosed = (
  <info-span>
    Thank you for your interest in participating in the <black-span>Whole Home Generator Pilot Program</black-span>. We are working hard to better ensure 
    resilience and safety in your home during bad weather. Please note that our <black-span>pilot program is currently closed</black-span>. 
    For questions, please email us at <pink-span><a href="mailto:communitybackup@entergy.com">communitybackup@entergy.com</a></pink-span>.
  </info-span>
);

export const getTransferSwitchOpen = (opcoData) => {

  const { opcoDisplayLong, opcoDisplayShort } = opcoData

  return (
    <info-span>
      Thank you for your interest in participating in the{" "}
      <black-span>
        Entergy {opcoDisplayLong} Transfer Switch with Surge Protection Pilot
        Program.
      </black-span>{" "}
      We are working hard to better ensure resilience and safety in your home
      during bad weather. Please note that{" "}
      <black-span>availability for the pilot program is limited</black-span> and
      available <black-span>to Entergy employees</black-span> in specific{" "}
      {opcoDisplayShort} Networks. To continue, sign-up and{" "}
      <black-span>submit your application by September 16, 2024.</black-span>{" "}
      After September 16, 2024, you can still apply for the Entergy Transfer
      Switch with Surge Protection Pilot Program but will be placed on a waiting
      list until availability. For questions, please email us at{" "}
      <pink-span>
        <a href="mailto:communitybackup@entergy.com">
          communitybackup@entergy.com
        </a>
      </pink-span>
      . 
    </info-span>
  );
};

export const getTransferSwitchClosed = (opcoData) => {

  const { opcoDisplayLong } = opcoData

  return (
    <info-span>
      Thank you for your interest in participating in the Entergy {opcoDisplayLong} Transfer Switch with Surge Protection Pilot Program.{" "}
      We are working hard to better ensure resilience and safety in your home during bad weather.{" "}
      <black-span>Please note the pilot program is now closed.</black-span>{" "}
      For questions or to be placed on a waiting list until further availability, please email us at{" "}
      <pink-span><a href="mailto:communitybackup@entergy.com">communitybackup@entergy.com</a></pink-span>.
    </info-span>
)};

